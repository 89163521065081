import { NavLink as NavLinkReactRouter } from "react-router-dom"

const NavLink = ({ to, children, ...rest }) => {
    return (
        <NavLinkReactRouter
            {...rest}
            className={({ isActive }) =>
                isActive ? 'activeLink' : ''
            }
            to={to}
        >
            {children}
        </NavLinkReactRouter>
    )
}

export default NavLink