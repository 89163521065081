import './pagebanner.scss'

const PageBanner = ({ backImage = "https://images.pexels.com/photos/2101137/pexels-photo-2101137.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" }) => {

    console.log(backImage)
    return (
        <div className="pagebanner">
            <div className="pagebanner__container">
                <div className="pagebanner__information">
                    <div className="pagebanner__information__letter">
                        <h1>Nuestros trabajos y proyectos tienen calidad y prosperidad</h1>
                    </div>
                </div>

                <div className="pagebanner__img" style={{ backgroundImage: `url(${backImage})` }} >
                    <div className="arrow__banner">
                        <i className="fa-solid fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageBanner