import { Swiper, SwiperSlide } from "swiper/react";

import { EffectFade, Autoplay } from "swiper";
import "./bigslider.scss"

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

const BigSlider = () => {

    const sedes = [
        {
            id: 1,
            title: "Calidad, seguridad y Estabilidad",
            name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, voluptates?',
            image: 'https://images.pexels.com/photos/461789/pexels-photo-461789.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia iste consequatur vero accusantium odit!'
        },
        {
            id: 2,
            title: "Cuidamos el medio ambiente",
            name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            image: 'https://images.pexels.com/photos/2097855/pexels-photo-2097855.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque cupiditate fugiat nihil error. Necessitatibus enim accusamus quas?!'
        },
        {
            id: 3,
            title: "Saneamiento y seguridad",
            name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            image: 'https://images.pexels.com/photos/1216589/pexels-photo-1216589.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque cupiditate fugiat nihil error. Necessitatibus enim accusamus quas?!'
        }
    ]

    return (
        <div className="sliderBig">
            <Swiper
                modules={[Autoplay, EffectFade]}
                spaceBetween={30}
                effect={"fade"}
                loop={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
            >
                {sedes?.map(sede => (
                    <SwiperSlide key={sede.id}>
                        <img src={sede.image} className="sliderImage" />
                        <div className="sliderBigTitle" data-aos="zoom-out-up">
                            <h1>{sede.title}</h1>
                        </div>
                        <div className="sliderTitleContainer">
                            <i className="fa-solid fa-chevron-right sliderIcon"></i>
                            <p className="sliderTitle">{sede.name}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div >
    )
}

export default BigSlider