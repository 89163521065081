import './contact.scss'

const Contact = () => {
    return (
        <div className='contact'>
            <i className="fa-brands fa-facebook contact__icon" data-aos="zoom-in-left" ></i>
            <i className="fa-solid fa-envelope contact__icon" data-aos="zoom-in-left" data-aos-delay="200"></i>
            <i className="fa-brands fa-linkedin-in contact__icon" data-aos="zoom-in-left" data-aos-delay="400"></i>
        </div>
    )
}

export default Contact