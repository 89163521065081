import { useParams } from "react-router-dom"
import PageBanner from "../../components/pagesbanner/PageSlider"
import Subheader from "../../components/subheader/Subheader"
import Application from "../../layout/Application"
import { business } from "../../data"
import "./business.scss"
import { useEffect, useState } from "react"

const Sanitation = () => {

    let { id } = useParams();

    const newId = Number(id);

    const [data, setData] = useState(null)


    useEffect(() => {
        const handleLoadData = async () => {
            const res = await business.filter(newBus => newBus.id === newId);
            setData(res[0])
        }

        handleLoadData()
    }, [newId])

    return (
        <Application>
            <PageBanner
                backImage={data?.image}
            />
            <div className="pages__business">
                <div className="page__container">
                    <Subheader business={business} />
                    {data ?
                        <div className="page__information">
                            <h1>{data?.name}</h1>
                            <p className="">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur, ipsum. Neque ea itaque fuga necessitatibus, suscipit laboriosam rerum autem blanditiis.</p>
                            <p className="">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur, ipsum. Neque ea itaque fuga necessitatibus..</p>
                            <div className="page__information__image">
                                {data?.photos.map((p, index) => (
                                    <img src={p} alt="image" key={index} data-aos="fade-up"
                                        data-aos-delay="200" />
                                ))}
                            </div>
                        </div>

                        :

                        <div className="page__information">
                            <div className="page__information__container">
                                <h1>Tenemos 6 lineas de negocio</h1>

                            </div>
                        </div>
                    }

                </div>
            </div>
        </Application>
    )
}

export default Sanitation