import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { Business, Home, About, Contact } from "../pages/index"

const MainRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />} />
                <Route path="negocio/:id" element={<Business />} />
                <Route path="nosotros" element={<About />} />
                <Route path="contacto" element={<Contact />} />
            </Routes>
        </BrowserRouter>
    )
}

export default MainRoute