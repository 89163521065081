import { motion, AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import CardBusiness from '../../components/cardBusiness/CardBusiness'
import Loader from '../../components/loader/Loader'
import BigSlider from '../../components/sliders/big/BigSlider'
import Application from '../../layout/Application'
import { page, transitionPage } from '../../lib/animation/Animation'
import { business } from '../../data'
import './home.scss'

const Home = () => {

    const [imagen, setImagen] = useState('https://images.pexels.com/photos/2101140/pexels-photo-2101140.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')

    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2500);
    }, [])

    return (
        <AnimatePresence exitBeforeEnter>
            {loading === true
                ?
                <Loader />
                :
                <motion.div
                    initial="out"
                    animate="in"
                    exit="out"
                    key={'home'}
                    variants={page}
                    transition={transitionPage}
                >
                    <Application>
                        <BigSlider />
                        <div className="business">
                            <div className="business__each">
                                <h1 className='business__title' data-aos="fade-up">Lineas de Negocio</h1>
                                <div className="business__container">
                                    {business.map((bus, index) => (
                                        <CardBusiness
                                            key={bus.id}
                                            name={bus.name}
                                            image={bus.image}
                                            descr={bus.descrip}
                                            setImagen={setImagen}
                                            backColor={bus.backColor}
                                            icon={bus.icon}
                                            setInfo={setInfo}
                                            position={index}
                                            id={bus.id}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="business__information"
                                style={{ backgroundImage: `url(${imagen})` }}
                                data-aos="fade-left"
                            >
                                <div className="business__information__content" data-aos="zoom-in-up" data-aos-delay="300">
                                    <h1>{info?.name || ' Tenemos 3 Lineas de negocio'}</h1>
                                    <p>{info?.descr || 'Cumplimos con todos los requisitos necesarios'}</p>
                                    {info?.link ?
                                        <a href="#">Leer mas</a>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </Application>
                </motion.div>
            }

        </AnimatePresence>
    )
}

export default Home