import Kallpa from "../../assets/image/logo.png"
import Banner from '../../components/banner/Banner'
import Values from "../../components/values/Values"
import { gallery, values } from "../../data"
import Application from '../../layout/Application'
import './about.scss'

const About = () => {
    return (
        <Application>
            <div className="about">
                <Banner
                    backImage={'https://images.pexels.com/photos/4993793/pexels-photo-4993793.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                    title='Sobre Nosotros'
                />
                <div className="about__container">
                    <div className="about__information" data-aos="fade-right" data-aos-delay="500">
                        <h1>Grupo Kallpa</h1>
                        <h3>¿Quienes somos?</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, voluptates incidunt. Cumque libero illo cupiditate dicta, perferendis totam neque consequatur ipsa laborum et mollitia explicabo quae, possimus natus voluptates laboriosam saepe molestiae deserunt. Saepe ipsam nobis mollitia eum, quas obcaecati!</p>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus perferendis sunt hic debitis iusto omnis recusandae alias et tenetur unde!</p>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo aperiam modi, omnis architecto obcaecati eos?</p>
                        <a href="#">TRABAJEMOS JUNTOS</a>
                    </div>
                    <div className="about__images" data-aos-delay="500">
                        {gallery.map(gal => (
                            <img src={gal.img} key={gal.id} alt="name" data-aos="fade-up" />
                        ))}
                    </div>
                </div>
                <div className="about__another">
                    <div className="about__mision" data-aos="fade-right">
                        <h1>Misión</h1>
                        <i className="fa-solid fa-angles-down"></i>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis obcaecati animi eos ab porro nisi minus harum doloremque aut dignissimos culpa aliquid perspiciatis, odio beatae? Quaerat molestiae ea excepturi ipsa.</p>
                    </div>
                    <div className="about__vision" data-aos="fade-left">
                        <h1>Visión</h1>
                        <i className="fa-solid fa-angles-down"></i>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae dignissimos rerum quae consectetur ratione adipisci, debitis officia deserunt modi ad voluptas fugit inventore esse sunt aliquam dolor doloremque nostrum minus ipsa enim tempora eius earum. Beatae, ut. Maxime, voluptas unde!</p>
                    </div>
                </div>
                <div className="about__values">
                    <div className="about__values__container">
                        <h1>NUESTROS VALORES</h1>
                        <div className="about__values__grid">
                            {values.map(val => (
                                <Values
                                    name={val.name}
                                    icon={val.icon}
                                    key={val.id}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Application>
    )
}

export default About