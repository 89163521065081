export const business = [
  {
    id: 120,
    name: "Edificaciones",
    image:
      "https://images.pexels.com/photos/8961131/pexels-photo-8961131.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    descrip: "Trabajamos para mejorar la via nacional",
    icon: "fa-solid fa-building",
    bigDescrip:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam voluptatibus quia sapiente iste expedita obcaecati enim non tenetur nobis sunt?",
    photos: [
      "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    ],
  },
  {
    id: 2321,
    name: "Saneamiento",
    icon: "fa-solid fa-shower",
    image:
      "https://images.pexels.com/photos/9543745/pexels-photo-9543745.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    descrip: "Cumplimos con los mejores estandares de saneamiento",
    bigDescrip:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam voluptatibus quia sapiente iste expedita obcaecati enim non tenetur nobis sunt?",
    photos: [
      "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    ],
  },
  {
    id: 3123,
    name: "Infraestructura vial",
    icon: "fa-solid fa-road",
    image:
      "https://images.pexels.com/photos/1021683/pexels-photo-1021683.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    descrip: "Realizamos carreteras para una mejor comunicacion vial",
    bigDescrip:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam voluptatibus quia sapiente iste expedita obcaecati enim non tenetur nobis sunt?",
    photos: [
      "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    ],
  },
  {
    id: 3454,
    name: "Bienes y servicios",
    icon: "fa-solid fa-briefcase",
    image:
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    descrip: "Realizamos carreteras para una mejor comunicacion vial",
    bigDescrip:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam voluptatibus quia sapiente iste expedita obcaecati enim non tenetur nobis sunt?",
    photos: [
      "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    ],
  },
  {
    id: 3245,
    name: "Minería",
    icon: "fa-solid fa-helmet-safety",
    image:
      "https://images.pexels.com/photos/2892618/pexels-photo-2892618.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    descrip: "Realizamos carreteras para una mejor comunicacion vial",
    bigDescrip:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam voluptatibus quia sapiente iste expedita obcaecati enim non tenetur nobis sunt?",
    photos: [
      "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    ],
  },
  {
    id: 6,
    name: "Gestión de proyectos",
    icon: "fa-solid fa-file",
    image:
      "https://images.pexels.com/photos/941555/pexels-photo-941555.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    descrip: "Realizamos carreteras para una mejor comunicacion vial",
    bigDescrip:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam voluptatibus quia sapiente iste expedita obcaecati enim non tenetur nobis sunt?",
    photos: [
      "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      "https://images.pexels.com/photos/4246120/pexels-photo-4246120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    ],
  },
];

export const gallery = [
  {
    id: 123,
    img: "https://images.pexels.com/photos/60008/brown-coal-energy-garzweiler-bucket-wheel-excavators-60008.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 213,
    img: "https://images.pexels.com/photos/1009926/pexels-photo-1009926.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 21343,
    img: "https://images.pexels.com/photos/2058738/pexels-photo-2058738.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 342,
    img: "https://images.pexels.com/photos/2097855/pexels-photo-2097855.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 897,
    img: "https://images.pexels.com/photos/4946886/pexels-photo-4946886.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 983,
    img: "https://images.pexels.com/photos/723905/pexels-photo-723905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 756,
    img: "https://images.pexels.com/photos/2892618/pexels-photo-2892618.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

export const values = [
  {
    id: 2342854,
    name: "Integridad",
    icon: "fa-solid fa-heart",
  },
  {
    id: 454321,
    name: "Compromiso",
    icon: "fa-solid fa-ruler",
  },
  {
    id: 905,
    name: "Perseverancia",
    icon: "fa-solid fa-check-double",
  },
  {
    id: 4560,
    name: "Liderazgo",
    icon: "fa-solid fa-users-line",
  },
  {
    id: 8475,
    name: "Respeto",
    icon: "fa-solid fa-circle",
  },
  {
    id: 2342,
    name: "Integridad",
    icon: "fa-solid fa-check-double",
  },
  {
    id: 4543,
    name: "Compromiso",
    icon: "fa-solid fa-users-line",
  },
  {
    id: 456,
    name: "Liderazgo",
    icon: "fa-solid fa-ruler",
  },
];
