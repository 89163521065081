const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__logo">
                    <h2 data-aos="fade-up">GRUPO KALLPA</h2>
                    <ul data-aos="fade-up">
                        <li>C. Leoncio Prado N° 525 - Calca - Cusco</li>
                        <li>Av. San Francisco de Asis - Ayacucho</li>
                    </ul>
                </div>

                <div className="footer_extra" data-aos="fade-up" data-aos-delay="200">
                    <h3 className="footer__subtitle">CONTÁCTANOS</h3>
                    <a href="">Trabajemos juntos</a>
                </div>
                <div className="footer__social" data-aos="fade-up" data-aos-delay="400">
                    <h3 className="footer__subtitle">REDES SOCIALES</h3>
                    <div className="footer__social__container">
                        <div className="footer__social__names">
                            <i className="fa-brands fa-facebook contact__icon"></i>
                            <p>Faceebook</p>
                        </div>
                        <div className="footer__social__names">
                            <i className="fa-solid fa-envelope contact__icon"></i>
                            <p>Gmail</p>
                        </div>
                        <div className="footer__social__names">
                            <i className="fa-brands fa-linkedin-in contact__icon"></i>
                            <p>Linkedin</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer