export const loading = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const header = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: -500,
  },
};

export const page = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const elements = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: 100,
  },
};

export const transitionPage = {
  duration: 0.4,
};

export const transition = {
  duration: 1,
};

export const transition2 = {
  delay: 1,
  duration: 0.5,
};
