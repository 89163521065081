import Header from './Header'
import Footer from "./Footer"
import Contact from "../components/contact/Contact"
import { useEffect } from "react"

const Application = ({ children }) => {

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        })
    }, [])

    return (
        <div>
            <>
                <Header />
                <Contact />
                {children}
                <Footer />
            </>

        </div>
    )
}

export default Application