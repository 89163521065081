import MainRoute from "./router/MainRoute";
import "./sass/main.scss";

function App() {
  return (
    <div>
      <MainRoute />
    </div>
  );
}

export default App;
