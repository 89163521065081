import "./values.scss"

const Values = ({ name, icon }) => {
    return (
        <div className="values" data-aos="fade-up">
            <i className={icon}></i>
            <h3>{name}</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, ipsum cumque qui quo voluptate laudantium!</p>
        </div >
    )
}

export default Values