import { Link } from 'react-router-dom'
import './cardbusiness.scss'

const CardBusiness = ({ name, image, descr, setImagen, setInfo, icon, id }) => {

    const changeBack = () => {
        setImagen(image)
        setInfo({ name, descr, link: '#' })
    }


    return (
        <Link to={"/negocio/" + id}>
            <div className={` cardBusiness `}
                onMouseEnter={changeBack}
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <div className="cardBusinessHead" >
                    <i className={`${icon} business__icon`}></i>
                    <h1>{name}</h1>

                </div>

            </div>
        </Link>
    )
}

export default CardBusiness