import Banner from '../../components/banner/Banner';
import Application from '../../layout/Application';
import './contact.scss'

const Contact = () => {
    return (
        <Application>
            <Banner
                backImage={'https://images.pexels.com/photos/3862371/pexels-photo-3862371.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'}
                title="Contactanos"
            />
            <div className="contact__alll">
                <div className="contact__map" data-aos="fade-up"
                    data-aos-delay="400">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.4799376014194!2d-71.95564728522554!3d-13.320394490627747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916ddf6012918777%3A0xdb6fb2d028d37920!2sC.%20Leoncio%20Prado%20525%2C%20Calca%2008121!5e0!3m2!1ses!2spe!4v1656207669419!5m2!1ses!2spe" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="contact__form__container" data-aos="fade-up"
                    data-aos-delay="400">
                    <h1>Trabajemos Juntos</h1>
                    <div className="contact__form" >
                        <div className="input__form" >
                            <label>Nombre</label>
                            <div className="input__form__input">

                                <input type="text" />
                                <div className="decorer"></div>
                            </div>
                        </div>
                        <div className="input__form">
                            <label>Email</label>
                            <div className="input__form__input">
                                <input type="text" />
                                <div className="decorer"></div>
                            </div>
                        </div>
                        <div className="input__form">
                            <label>Nombre de la empresa</label>
                            <div className="input__form__input">
                                <input type="text" />
                                <div className="decorer"></div>
                            </div>
                        </div>
                        <div className="input__form">
                            <label>Teléfono</label>
                            <div className="input__form__input">
                                <input type="text" />
                                <div className="decorer"></div>
                            </div>
                        </div>

                    </div>
                    <div className="text__area">
                        <label>¿Que proyecto tienes en mente?</label>
                        <div className="input__form__input">
                            <textarea type="text" />
                            <div className="decorer"></div>
                        </div>
                    </div>
                    <button>Enviar</button>
                </div>
            </div>
        </Application>
    )
}

export default Contact