import "./banner.scss"

const Banner = ({ backImage, title }) => {
    return (
        <div className='banner' style={{ backgroundImage: `url(${backImage})` }}>
            <h1>{title}</h1>
            <i className="fa-solid fa-angle-down"></i>
        </div>
    )
}

export default Banner