import { motion } from 'framer-motion'
import Kallpa from '../../assets/image/logo.png'
import { elements, loading, transition, transition2 } from '../../lib/animation/Animation'
import './loader.scss'

const Loader = () => {
    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={loading}
            key={'loader'}
            className="loader">
            <div className="loader__container">
                <motion.img initial="out"
                    animate="in"
                    exit="out"
                    variants={elements}
                    transition={transition}
                    src={Kallpa} className="loader__image" alt='Grupó Kallpa' />
                <motion.div
                    initial="out"
                    animate="in"
                    exit="out"
                    variants={loading} transition={transition2}>
                    <h1>Realizando cambios en beneficio de la poblacion</h1>
                </motion.div>
            </div>
        </motion.div >
    )
}

export default Loader