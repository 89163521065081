import { AnimatePresence, motion } from "framer-motion"
import NavLink from '../router/NavLink'
import { Link } from 'react-router-dom'
import Kallpa from '../assets/image/logo.png'
import { useState } from 'react'
import { header, transitionPage } from "../lib/animation/Animation"

const Header = () => {

    const [menu, setMenu] = useState(false)

    return (
        <header>
            <div className="header__contact">
                <Link to="/contacto">
                    CONTACTANOS
                </Link>
            </div>
            <div className="header__container">
                <div className="header__logo">
                    <Link to="/" className='header__logo'>
                        <img src={Kallpa} alt="" />
                        <h1 className="header-logo">Grupo Kallpa</h1>
                    </Link>
                </div>
                <div className="header__options">
                    <ul>
                        <NavLink to="/nosotros">
                            <li>NOSOTROS</li>
                        </NavLink>
                        <NavLink to="/negocio/todos">
                            <li>LINEA DE NEGOCIO</li>
                        </NavLink>
                        <li>PROYECTOS</li>
                        <li>NOVEDADES</li>
                    </ul>
                </div>
            </div>
            {/* header mobile */}
            <div className="header__mobile__all">
                <div className="header__mobile">
                    <div className="header__mobile__logo">
                        <img src={Kallpa} alt="" />
                        <h1 className="header-logo">Grupo Kallpa</h1>
                    </div>
                    <div className="header__mobile__burguer">
                        <i className="fa-solid fa-bars" onClick={() => setMenu(!menu)}></i>
                    </div>
                </div>
                <AnimatePresence>
                    {menu === true ?
                        <motion.div
                            initial="out"
                            animate="in"
                            exit="out"
                            key={'home'}
                            variants={header}
                            transition={transitionPage}
                            className="header__mobile__options">
                            <img src={Kallpa} alt="" />
                            <ul>
                                <li>NOSOTROS</li>
                                <li>LINEA DE NEGOCIO</li>
                                <li>PROYECTOS</li>
                                <li>NOVEDADES</li>
                                <li className='contact__mobile'>CONTACTANOS</li>
                            </ul>
                        </motion.div>
                        :
                        null
                    }
                </AnimatePresence>

            </div>
        </header>
    )
}

export default Header