import { Link } from 'react-router-dom'
import './subheader.scss'

const Subheader = ({ business }) => {
    return (
        <div className="subheader" data-aos="fade-right">
            <ul>
                {business.map(bus => (
                    <Link key={bus.id} to={"/negocio/" + bus.id}>
                        <li
                            data-aos="fade-up"
                            data-aos-delay="200"
                        ><i className={bus.icon}></i> <p>{bus.name}</p></li>
                    </Link>
                ))}
            </ul>
        </div>
    )
}

export default Subheader